@import 'styles/abstracts/colors';

.deliverable-cancel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .row-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-dark-red;

    .icon-title {
      display: flex;
      gap: 1rem;
      align-items: center;
      font-size: 20px;

      h3 {
        font-weight: 600;
      }
    }
  }

  .label-input {
    width: 100%;
    color: #083671;
    font-size: larger;
    display: flex;
    border-radius: 7px;
    line-height: 2em;

    .deliverables-input {
      padding: 0.7em;
      padding-left: 1em;
      font-size: 12px;
      border-radius: 7px;
    }
  }

  .submit-button {
    width: 50%;
    background-color: $color-dark-red !important;
    color: white;
  }

  .submit-button:disabled {
    color: #a4a4a4 !important;
    background-color: white !important;
  }
}
