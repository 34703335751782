@import 'styles/abstracts/colors';
@import 'styles/abstracts/variables';

.dialog-dates {
  height: 100%;
  min-width: 30vw;
  z-index: 1;
  min-height: 30vh;

  ul.materialui-daterange-picker-MuiList-root-257 {
    display: none;
  }
}

.border-bottom-input {
  border: none;
  border-bottom: 1px solid $color-dark-grey;
  background-color: transparent;
  box-shadow: $default-box-shadow;
}

.title-dialog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divider-catalog-details {
  margin-right: 1rem;
  margin-left: 1rem;
}

.client-information-container {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-direction: column;
}

.space-between {
  justify-content: space-between !important;
}

.padding-tight {
  padding: 1.7rem !important;
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}
