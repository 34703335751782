.menu_preview_order_container {
  .container_menu {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;

    .icon-container {
      margin-right: 0.5rem;
      svg {
        width: 1rem;
        height: 1rem;
        color: black;
      }
    }
  }
}
