@import 'styles/abstracts/colors';

.progress_bar {
  &.red {
    background-color: $color-red;
  }

  &.yellow {
    .MuiLinearProgress-bar1Determinate {
      background-color: $color-yellow !important;
    }
  }

  &.green {
    .MuiLinearProgress-bar1Determinate {
      background-color: $color-green;
    }
  }

  &.light-blue {
    .MuiLinearProgress-bar1Determinate {
      background-color: #418bec;
    }
  }

  &.blue {
    .MuiLinearProgress-bar1Determinate {
      background-color: #0f5ee9;
    }
  }

  &.dark-blue {
    .MuiLinearProgress-bar1Determinate {
      background-color: #043474;
    }
  }
}
