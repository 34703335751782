#container-content {
  // height: 100%;

  #deliverable-sheet-container {
    height: 100%;

    .tab-content {
      height: 100%;
      &.hide {
        display: none;
      }
    }

    .iframe-viewer {
      display: block;
      width: 100%;
      height: 100vh;
    }
    .actions-container {
      display: flex;
      gap: 1rem;
      padding: 2rem 0;

      .download-action {
        padding: 1rem;
        font-size: 16;
        .pdf-download-icon {
          width: 16px;
          height: 16px;
          margin-right: 1rem;
        }
      }
    }
  }
}
