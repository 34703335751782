@import 'styles/abstracts/colors';

.drawer-edit-complexity {
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: 1rem 2rem;
  font-size: 14px;
  min-width: 40em;
  max-width: 40em;
  overflow: auto;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .column {
    display: flex;
    flex-direction: column;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .add-button {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    .submit-button {
      width: 50%;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  h3 {
    display: flex;
    font-size: 32px;
    color: #083671;
  }

  .label-input {
    color: black;
    font-size: larger;
    border-radius: 7px;
    line-height: 2em;
    width: 100%;
  }

  input,
  label,
  .MuiInputBase-input {
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    //   color: $color-dark-grey;
    border: none;
    padding: 0.7rem;
  }

  .active-blue {
    &:active,
    &:focus,
    &:invalid {
      background-color: white !important;
      border: 2px solid $color-blue;
      box-shadow: none;
      -moz-box-shadow: none;
      outline: none;
    }
  }

  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .complexity-add-button {
      display: flex;
      justify-content: center;
      align-items: center;

      .add-icon {
        margin-right: 1rem;
      }

      .loader {
        color: white;
      }
    }
  }

  .trash-icon {
    margin-top: 1.5rem;
  }

  .background-grey {
    background-color: $color-border-grey;
  }
}
