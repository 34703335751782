.admin-container {
  overflow: auto;
  width: 100%;
  min-height: 40vh;
  .filters-container {
    display: flex;
    width: 100%;
    padding: 1rem;

    .filters {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      .submit-button {
        margin-bottom: 1rem;
        height: 2.5rem;
      }
    }
  }
  .container_name_admin,
  .MuiFormControlLabel-label {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 1rem;
  }

  .table_container {
    max-width: 100%;
    table-layout: fixed;
    width: 100%;
    .MuiTableCell:first-child {
      width: 1px;
      white-space: nowrap;
    }
    .MuiFormControlLabel-root {
      width: 100%;
    }
    td {
      white-space: nowrap;
    }
    .MuiTableCell-root {
      padding: 0;
      padding-right: 10px;
    }
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }
  .validate_button,
  .reload_button {
    margin: 0 auto;
    .MuiSvgIcon-root {
      margin-right: 0;
    }
  }
  .reload {
    .MuiSvgIcon-root {
      margin-right: 0;
    }
  }
}

.dialog_add_user {
  width: 100%;
  .name_container {
    width: 100%;
    display: flex;
    .name_input_container_firstname {
      width: 90%;
      margin-right: 1rem;
    }
    .name_input_container {
      width: 90%;
      margin-right: 1rem;
    }
  }
  .submit-button-icon-container {
    display: flex;
    align-items: center;
  }
}
