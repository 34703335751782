.kpi {
  display: flex;
}

.kpi__icon {
  margin-right: 15px;
}

.kpi__rating {
  margin-right: 15px;
}
