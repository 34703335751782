@import '../../styles/abstracts/colors';
@import '../../styles/abstracts/variables';

.user-navbar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.toggle-widget-button {
  margin-right: 15px;
}

.user-menu {
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.user-menu__avatar {
  margin-right: 15px;
}

.user-menu__name {
  margin-right: 15px;
  color: $color-very-dark-blue;
}

.MuiMenu-paper {
  background-color: white !important;
  fill: transparent !important;
  font-weight: normal;

  .MuiList-padding {
    padding: none !important;

    .MuiListItem-button {
      height: 2em;
      width: auto;

      .MuiIconButton-root {
        font-size: 1em;
        color: $color-blue;

        &:hover {
          background-color: transparent;
        }

        .MuiSvgIcon-root {
          padding-left: 0.3em;
        }
      }
    }
  }
}
