@import 'styles/abstracts/colors';

.MuiAlert-root {
  &.with_undo {
    .MuiAlert-message {
      width: 100%;
      padding: 0;
      text-align: center;
      align-items: center;
      display: flex;

      .very_large {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .MuiIconButton-root {
        color: #cd665f;
        font-size: 1rem;
        padding: 0;

        span {
          font-weight: 900;
          margin-left: 0.2rem;
          font-size: small;
        }
      }
    }
  }
}

.success {
  &.MuiLinearProgress-colorPrimary {
    background-color: white;
  }

  .MuiLinearProgress-bar1Determinate {
    background-color: #56dbb2;
  }
}
