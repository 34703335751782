#modal-cancel-acceptance {
  .MuiPaper-root {
    border-radius: 18px;
    padding: 0.2rem 1rem;

    .MuiDialogTitle-root {
      display: flex;
      color: #043474;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .MuiDialogContentText-root {
      color: #043474;
      font-size: 1rem;
      font-weight: 700;

      &.warning {
        color: #c23f34;

        .warning-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
}
