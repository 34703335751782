.badge-kpi {
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.blue {
    color: #0f5ee9;
    background-color: #eaf1fd;
  }

  &.red {
    background-color: #fdeaea;
    color: #ce3030;
  }

  &.orange {
    background-color: #fff8da;
    color: #ffab38;
  }

  &.green {
    color: #20cc8d;
    background-color: #eafdeb;
  }

  .badge-kpi-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      width: 40px;
    }
  }

  .badge-kpi-value {
    font-weight: bold;
    color: #043474;
    font-size: 1.1rem;
  }
}
