.admin_tenant_settings_container {
  .table_container {
    width: 50%;
  }
  .cell_year_container {
    width: 80%;
  }
  .inline {
    display: block;
    width: 100%;
    height: 100%;
  }
}
