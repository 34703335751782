.columnHeader-calendar {
  &.with_year {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: #b2bcd5;
    }
  }

  .year_displayed {
    position: absolute;
    top: 5px;
    font-size: 0.7rem;
  }
}

.cell-order-affectation-consultant {
  padding: 5px;
}

.MuiDataGrid-columnHeader--alignCenter {
  .MuiDataGrid-columnHeaderTitleContainerContent {
    justify-content: center;
  }
}
