@import 'styles/abstracts/colors.scss';

.process_modal {
  .MuiDialog-paper {
    min-width: 40vw;
  }

  .process_modal-title {
    font-size: 1rem;
    font-weight: 600;
    color: $color-dark-blue;
  }

  .process_modal-text {
    font-size: 0.75rem;
    font-weight: 600;
    color: $color-dark-blue;
    margin-bottom: 1rem;
  }
}
