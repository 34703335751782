@import 'styles/abstracts/colors';

.drawer_edit_container_drawer {
  .order_edit_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title_edit_order {
      font-size: 2rem !important;
    }

    .input-section {
      .label-input {
        color: #275087;
        font-weight: bolder;
      }
    }

    .title {
      font-size: 1.3rem;
      margin: 0;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      .top_drawer_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title_content {
          display: flex;
          flex-direction: row;
          align-items: center;

          h2 {
            margin-left: 1rem;
          }
        }
      }
    }

    .description_text {
      font-size: 1rem;
      font-weight: bolder;
    }

    .client_section {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }

    .MuiOutlinedInput-input {
      padding: 0;
      border-radius: 7px;
    }

    .submit_container {
      display: flex;
      justify-content: center;
      padding-top: 0.5rem;
      padding-bottom: 1rem;

      .submit-button {
        width: 90% !important;
      }
    }
  }

  .save-container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
