.rate-assess-container {
  .rate {
    width: 2.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0063e5;
    border: 1px solid #0063e5;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 5px;
    height: 2.5em;

    &.active {
      background-color: #0063e5;
      color: white;
    }
  }
}

.average-rating-reminder {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .reminder {
    font-style: italic;
  }
}
