@import 'styles/abstracts/colors';

.rate-container {
  .rate {
    width: 1.4em;
    height: 1.4em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: xx-large;
    border-radius: 5px;
  }

  .small {
    width: 2.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: small;
    border-radius: 5px;
    height: 2.5em;
  }

  .E {
    background-color: $color-rating-a;
  }

  .D {
    background-color: $color-rating-b;
  }

  .C {
    background-color: $color-rating-c;
  }

  .B {
    background-color: $color-rating-d;
  }

  .A {
    background-color: $color-rating-e;
  }

  .S {
    background-color: $color-rating-s;
  }
}
