.no-style-button {
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  display: flex;
  justify-content: flex-start;
  background-color: inherit;
}
.loader_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
