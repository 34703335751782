.tag-cell,
.tag-cell-popover {
  .chip {
    &-added {
      background: #0f5ee9;
      color: #fff;
    }

    &-cancelled {
      background: #ce3030;
      color: #fff;
    }

    &-custom {
      background: #20b8cc;
      color: #fff;
    }

    &-updated {
      background: #043474;
      color: #fff;
    }
  }
}
