$color-light-blue: #408aec;
$color-medium-blue: #418bec;
$color-blue: #2465dd;
$color-dark-blue: #083671;
$color-very-dark-blue: #043474; // TODO : rename
$color-darker-blue: #275087;
$color-light-grey: #f7f7f7;
$color-border-grey: #eaeaea;
$color-border-grey-darker: #c8c8c8;
$color-grey: #a4a4a4;
$color-dark-grey: #616161;
$color-red: #f34e3b;
$color-dark-red: #c23f34;
$color-green: #0acb8f;
$color-light-green: #a5ead1;
$color-dark-green: #00aa27;
$color-yellow: #ffb833;
$color-rating-a: #abc4e5;
$color-rating-b: #8cb8f3;
$color-rating-c: #66a1ef;
$color-rating-d: #2d7ce4;
$color-rating-e: #0063e5;
$color-rating-s: #2465dd;
$color-light-black: #646464;
$color-orange: #ef9400;
$color-lighter-grey: #f4f4f4;
$color-background-blue: #0f5ee9;
$color-card-blue: #e6f1ff;
$color-pastel-blue: #b2bcd5;

$color-background-blue: #0f5ee912;

// Refacto colors
$bg-color: #f2f6fd;
$blue-grey: #b2bcd5;
