#sheet-preview-container {
  height: 100%;
  .actions-container {
    display: flex;
    padding: 2rem 0;
    .download-action {
      padding: 1rem;
      font-size: 18px;
      .pdf-download-icon {
        width: 18px;
        height: 18px;
        margin-right: 1rem;
      }
    }
  }
}
