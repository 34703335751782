.summary-datagrid {
  overflow: auto;

  .MuiDataGrid-columnHeaders {
    background-color: transparent;
  }

  .MuiDataGrid-columnHeader {
    font-size: 0.9rem;
    font-weight: 600;

    & .MuiBox-root {
      font-weight: 600;
    }

    @media (max-width: 1280px) {
      font-size: 0.9rem;
    }

    &.MuiDataGrid-withBorder {
      border: 0 none;
    }
  }

  .blue-background {
    background-color: #bedefe !important;
    color: #0f5ee9;
  }

  .columnHeader-calendar.with_year {
    &::before {
      content: none;
    }
  }

  .MuiDataGrid-row {
    &:hover {
      background-color: #bedefe;

      .MuiDataGrid-cell {
        color: #0f5ee9;
      }
    }
  }

  .MuiDataGrid-cell {
    font-size: 0.9rem;
    font-weight: 600;

    @media (max-width: 1280px) {
      font-size: 0.9rem;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }
}
