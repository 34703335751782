.documents-page {
  display: flex;
  flex-direction: column;
  flex: auto;
  .iframe-container {
    display: flex;
    flex: auto;
    .iframe {
      flex: auto;
    }
  }
}
