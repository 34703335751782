.terms-of-use-dialog {
  .pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    .pdf {
      border: 1px solid #b2bcd5;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 40vh;
      overflow: auto;
    }
    .button-pdf-download {
      position: absolute;
      top: 15px;
      left: 15px;
    }

  }
}
