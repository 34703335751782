@import 'styles/abstracts/colors';

.management_header_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  .client_title {
    display: flex;
    font-size: 18px;
    color: #a4a4a4;
    font-weight: 500;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @media (max-width: 1000px) {
      justify-content: flex-start;
      align-items: center;
    }
  }

  .title {
    display: flex;
    font-size: 35px;
    font-weight: 600;
    color: #083671;
    margin-top: -15px;
  }

  .options {
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: flex-end;

    .search_container {
      display: flex;
      margin-top: -15px;
    }
  }

  .search_textfield {
    margin-right: 2rem;
    font-size: 12px;
  }

  .filters-button {
    padding: 0.5rem 2.6rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 12px;
  }
}
