@import 'styles/abstracts/colors';

.graphique {
  .projection {
    background-color: #eaf1fd;
    padding: 1rem;

    .content {
      font-weight: 500;

      .title {
        $color: $color-darker-blue;
      }
    }
  }

  .recharts-legend-wrapper {
    display: none;
  }

  .recharts-text {
    color: #0f62e6;
    font-size: bold;
  }

  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .row_display_start {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;

    .column {
      margin-right: 1rem;

      .price_display {
        font-size: 1.4rem;
        font-weight: 900;
      }

      .title_column {
        color: #35588b;
        font-size: 0.9rem;
      }

      .ordered {
        color: #4a8bea;
      }

      .completion {
        color: #0f62e6;
      }

      .validated {
        color: #0c3872;
      }

      .accepted {
        color: #62cc9c;
      }
    }
  }
}
