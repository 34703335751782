.layout__content {
  display: flex;
  flex-direction: column;
  margin: 30px;
  flex-grow: 1;
}

.layout__column {
  display: flex;
  flex-direction: column;
  margin-left: 140px;
  min-height: 100vh;
  transition: margin 0.4s;
  position: relative;

  &.isOpened {
    @media (max-width: 899px) {
      &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
      }
    }
  }

  @media (max-width: 899px) {
    margin-left: 0;
  }
}
