.assess-sign-modal {
  .MuiDialog-paperWidthSm {
    border-radius: 15px;
    padding: 1rem;
    max-width: 600px;

    .MuiDialogTitle-root {
      color: #043474;
      font-weight: 600;
    }
    .text-modal {
      color: #043474;
    }

    .text-button {
      color: #043474;
      font-weight: 600;
      background-color: white;
    }

    .Mui-disabled {
      color: white;
      background-color: #0f5ee94d;
    }

    .textarea-modal {
      border-radius: 10px;
      border: 1px solid #b2bcd5;
    }
  }
}
